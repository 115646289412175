import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 16em 0;
        text-align: left;

        background-image: url(${require('Theme/images/online/hero-bg.jpg')});
        background-position: center;
        background-size: cover;

        .hero-top-headline {
            color: #F76461;
            font-size: 1.2em;
            font-weight: 900;
            margin-bottom: 1em;
        }

        .hero-headline {
            font-size: 2.6em;
            font-weight: 900;
            color: white;
            max-width: 22em;
        }

        .hero-subheadline {
            font-size: 1.6em;
            font-weight: 300;
            color: white;
            margin-top: .5em;
        }

        .component-button {
            margin-top: 3em;
        }

        @media (max-width: ${variables.tabletL}) {}
        @media (max-width: ${variables.mobileL}) {
            .hero-headline {
                font-size: 2.2em;
            }

            .hero-subheadline {
                font-size: 1.4em;

                br {
                    display: none;
                }
            }
        }
    `;

