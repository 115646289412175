import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { COOKIE_LEAD_ID } from 'Consts/cookies';
import {
    PUBLIC_RESERVATIONS_ONLINE,
    PUBLIC_LP_ONLINE_SURVEY,
} from 'Consts/routes';

import { getProvider } from 'Services/Cookies';

import { withVariables } from 'Utils/string';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';
import Hero from 'Components/public/online/Hero';
import Testimonials from 'Components/public/online/Testimonials';
import Examples from 'Components/public/online/Examples';
import Video from 'Components/public/online/Video';
import Team from 'Components/public/online/Team';
import HowItWorks from 'Components/public/online/HowItWorks';

export default class PublicReservations extends Component {
    static propTypes = {};
    static defaultProps = {};
    state = {
        leadId: getProvider().get(COOKIE_LEAD_ID),
    }

    render() {
        const { leadId } = this.state;

        const redirectPath = leadId
            ? withVariables(PUBLIC_RESERVATIONS_ONLINE.path, {}, { leadId })
            : withVariables(PUBLIC_LP_ONLINE_SURVEY.path);

        return (
            <StyledComponent
                className={classnames({
                    'component-lp-online': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                <header className="header">
                    <LayoutContainer>
                        <div className="row">
                            <div className="logo">
                                <img src={require('Theme/images/logo-color.png')} />
                            </div>
                            <div className="controls">
                                <Link to={redirectPath}>
                                    <Button
                                        size="medium"
                                        layout="wide"
                                        styleVersion={2}
                                    >
                                        Wypróbuj bezpłatnie
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </LayoutContainer>
                </header>
                <Hero redirectPath={redirectPath} />
                <HowItWorks redirectPath={redirectPath} />
                <Examples redirectPath={redirectPath} />
                <Video />
                <Team redirectPath={redirectPath} />
                <Testimonials />
            </StyledComponent>
        );
    }
}
