import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';

export default class PublicOnlineTestimonials extends Component {
    static propTypes = {};
    static defaultProps = {};
    state = {}

    render() {
        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-testimoanials': true,
                })}
                styles={require('./styles')}
            >
                <LayoutContainer maxSize="s">
                    <div className="row">
                        <div className="col-testimonial">
                            <div
                                className="testimonial-image"
                                style={{
                                    backgroundImage: `url(${require('Theme/images/online/testimonial-example.jpg')})`,
                                }}
                            />
                            <div className="testimonial-copy">
                                <div className="testimonial-header">
                                    <p className="testimonial-author">
                                        <span className="icon">„</span> Szymon Sztaba
                                    </p>
                                    <p className="testimonial-date">
                                        1 Kwietnia 2019
                                    </p>
                                </div>
                                <p className="testimonial-paragraph">
                                    Trenerka FitAdept jest niesamowitą kobietą, która mimo młodego wieku ma ogromne doświadczenie.
                                    Treningi z Nią to sama przyjemność, a wyzwania stawia mi właściwie co trening.
                                    Dba również o moją kontuzjowaną nogę co jest dla mnie ważne bo dążymy do pełnej sprawności po operacji.
                                    <br /><br />
                                    Kontakt poza treningiem też ma dla mnie znaczenie bo wiem że zawsze moge spytać o swoje rozterki
                                    żywieniowe czy związane resztą moich treningów.
                                    <br /><br />
                                    Zdecydowanie Polecam 11/10!!😍😍
                                </p>
                            </div>
                        </div>
                        <div className="col-fb-review">
                            <div className="fb-content">
                                <img
                                    className="fb-logo"
                                    src={require('Theme/images/online/logo-fb.png')}
                                />
                                <img
                                    className="stars"
                                    src={require('Theme/images/online/stars.png')}
                                />
                                <span className="review">
                                    4.8/5.0
                                </span>
                                <span className="review-count">
                                    Na podstawie 154 opinii
                                </span>
                            </div>
                        </div>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
