import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 4em 0;

        .examples-headline {
            color: #1E1E1E;
            font-weight: 900;
            font-size: 2.2em;
            text-align: center;
        }

        .video {
            margin-top: 1em;

            iframe {
                width: 100%;
                height: 45em;
            }
        }


        @media (max-width: ${variables.tabletL}) {
            .video {
                iframe {
                    height: 30em;
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {
            .video {
                iframe {
                    height: 20em;
                }
            }
        }
    `;

