import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicOnlineTeam extends Component {
    static propTypes = {
        redirectPath: PropTypes.string.isRequired,
    };
    static defaultProps = {};
    state = {}

    render() {
        const { redirectPath } = this.props;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-team': true,
                })}
                styles={require('./styles')}
            >
                <LayoutContainer maxSize="s">
                    <div className="row">
                        <div
                            className="col-image"
                            style={{
                                backgroundImage: `url(${require('Theme/images/online/team.jpg')})`,
                            }}
                        />
                        <div className="col-copy">
                            <h4 className="team-headline">
                                Największa w Polsce sieć certyfikowanych trenerów personalnych, fizjoterapeutów i dietetyków
                            </h4>
                            <p className="team-copy">
                                W FitAdept ćwiczysz z prawdziwym trenerem - ekspertem treningu personalnego, który pomaga Ci osiągnąć Twoje cele. Przeprowadziliśmy ponad 100 000 treningów personalnych i tysiące skutecznych metamorfoz. Pomagamy osobom z Hashimoto, po kontuzjach, młodym mamom, seniorom. Zmieniamy styl życia na zdrowe życie.
                            </p>
                            <Link to={redirectPath}>
                                <Button
                                    size="large"
                                    layout="wide"
                                    styleVersion={2}
                                >
                                    Wypróbuj bezpłatnie
                                </Button>
                            </Link>
                        </div>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
