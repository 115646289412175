import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicOnlineHero extends Component {
    static propTypes = {
        redirectPath: PropTypes.string.isRequired,
    };
    static defaultProps = {};
    state = {}

    render() {
        const { redirectPath } = this.props;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-hero': true,
                })}
                styles={require('./styles')}
            >
                <LayoutContainer maxSize="s">
                    <h2 className="hero-top-headline">
                        Swoje cele zamienisz w rezultaty - 1na1 z trenerem!
                    </h2>
                    <h1 className="hero-headline">
                        Zacznij już dzisiaj treningi ze specjalistą na żywo. W domu, plenerze, podróży -  nasz zespół pilnuje Twojego celu i zdrowia wszędzie!
                    </h1>
                    <Link to={redirectPath}>
                        <Button
                            size="large"
                            layout="wide"
                            styleVersion={2}
                        >
                            Wypróbuj bezpłatnie
                        </Button>
                    </Link>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
