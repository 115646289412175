import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';

export default class PublicOnlineVideo extends Component {
    static propTypes = {};
    static defaultProps = {};
    state = {}

    render() {
        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-video': true,
                })}
                styles={require('./styles')}
            >
                <LayoutContainer maxSize="s">
                    <h3 className="examples-headline">
                        Ćwicz wszędzie! bezpiecznie,efektywnie i z motywacją!
                    </h3>
                    <div className="video">
                        <iframe
                            src="https://player.vimeo.com/video/403030928"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                        />
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
