import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 4em 0;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .col-image {
                background-position: center;
                background-size: cover;
                width: 45%;
                min-height: 25em;
            }
            .col-copy {
                padding: 2em 0;
                text-align: left;
                width: 50%;

                .team-headline {
                    font-size: 1.8em;
                    color: #1E1E1E;
                    font-weight: 900;
                }
                .team-copy {
                    font-size: 1.2em;
                    color: #1E1E1E;
                    font-weight: 300;
                    margin-top: 1em;
                }

                .component-button {
                    margin-top:1em;
                }
            }
        }


        @media (max-width: ${variables.tabletL}) {
            .row {
                flex-direction: column;

                .col-image {
                    width: 100%;
                }
                .col-copy {
                    width: 100%;
                    margin-top: 1em;
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {}
    `;

