import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicOnlineTeam extends Component {
    static propTypes = {
        redirectPath: PropTypes.string.isRequired,
    };
    static defaultProps = {};
    state = {}

    render() {
        const { redirectPath } = this.props;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-team': true,
                })}
                styles={require('./styles')}
            >
                <LayoutContainer maxSize="s">
                    <div className="row">
                        <div className="col-copy">
                            <h4 className="hiw-headline">
                                Jak działa FitAdept Online?
                            </h4>
                            <div className="hiw-steps">
                                <div className="hiw-step">
                                    <img
                                        className="hiw-step-icon"
                                        src={require('Theme/images/online/hiw-step-1.png')}
                                    />
                                    <p className="hiw-step-title">
                                        Trenujesz w dowolnym miejscu - Twój trener jest z Tobą żywo
                                    </p>
                                </div>
                                <div className="hiw-step">
                                    <img
                                        className="hiw-step-icon"
                                        src={require('Theme/images/online/hiw-step-4.png')}
                                    />
                                    <p className="hiw-step-title">
                                        Nie musisz mieć sprzętu sportowego
                                    </p>
                                </div>
                                <div className="hiw-step">
                                    <img
                                        className="hiw-step-icon"
                                        src={require('Theme/images/online/hiw-step-5.png')}
                                    />
                                    <p className="hiw-step-title">
                                        Wystarczy telefon lub laptop z internetem i Twoja motywacja!
                                    </p>
                                </div>
                                <div className="hiw-step">
                                    <img
                                        className="hiw-step-icon"
                                        src={require('Theme/images/online/hiw-step-6.png')}
                                    />
                                    <p className="hiw-step-title">
                                        Krok po kroku osiągasz swoje cele!
                                    </p>
                                </div>
                                <Link to={redirectPath}>
                                    <Button
                                        size="large"
                                        layout="wide"
                                        styleVersion={2}
                                    >
                                        Pierwszy trening za darmo
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-image"
                            style={{
                                backgroundImage: `url(${require('Theme/images/online/phone-mock.png')})`,
                            }}
                        />
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
