import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 4em 0;
        background-position: center;
        background-size: cover;
        background-image: url(${require('Theme/images/online/fa-dark-bg.jpg')});

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .col-image {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 45%;
                height: 50em;
            }
            .col-copy {
                color: white;
                text-align: left;

                .hiw-headline {
                    font-size: 3.2em;
                    font-weight: 900;
                }

                .hiw-steps {
                    margin-top: 2em;

                    .hiw-step {
                        margin-bottom: 1em;
                        padding-bottom: 1em;
                        border-bottom: 1px solid #333333;

                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .hiw-step-icon {
                            width: 3em;
                            height: auto;
                            margin: 0;
                        }
                        .hiw-step-title {
                            margin-left: 1em;
                            font-weight: 900;
                            font-size: 1.4em;
                        }
                    }
                }
            }
        }


        @media (max-width: ${variables.tabletL}) {
            .row {
                flex-direction: column-reverse;

                .col-image {
                    width: 100%;
                    height: 35em;
                }

                .col-copy {
                    width: 100%;
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {}
    `;

