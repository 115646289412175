import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicOnlineExamples extends Component {
    static propTypes = {
        redirectPath: PropTypes.string.isRequired,
    };
    static defaultProps = {};
    state = {}

    render() {
        const { redirectPath } = this.props;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-online-examples': true,
                })}
                styles={require('./styles')}
            >
                <LayoutContainer maxSize="s">
                    <h3 className="examples-headline">
                        To działa! Tym razem Ci się uda!
                    </h3>
                    <div className="boxes">
                        <div
                            className="box"
                            style={{
                                backgroundImage: `url(${require('Theme/images/online/box-1.jpg')})`,
                            }}
                        >
                            <p className="box-copy">
                                Trenujesz z dowolnego miejsca z najlepszym trenerem
                            </p>
                        </div>
                        <div
                            className="box"
                            style={{
                                backgroundImage: `url(${require('Theme/images/online/box-2.jpg')})`,
                            }}
                        >
                            <p className="box-copy">
                                Program zarówno dla osób początkujących i ze sprecyzowanymi celami
                            </p>
                        </div>
                        <div
                            className="box"
                            style={{
                                backgroundImage: `url(${require('Theme/images/online/box-3.jpg')})`,
                            }}
                        >
                            <p className="box-copy">
                                Trening, który jest zawsze skoncentrowany na Tobie
                            </p>
                        </div>
                    </div>
                    <div className="footer">
                        <Link to={redirectPath}>
                            <Button
                                size="large"
                                layout="wide"
                                styleVersion={2}
                            >
                                Zacznij teraz
                            </Button>
                        </Link>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
