import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 4em 0;

        .examples-headline {
            color: #1E1E1E;
            font-weight: 900;
            font-size: 2.2em;
            text-align: center;
        }

        .boxes {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-top: 2em;

            .box {
                background-position: center;
                background-size: cover;
                width: 31%;
                padding: 3em;
                height: 35em;

                display: flex;
                justify-content: center;
                align-items: flex-end;

                .box-copy {
                    text-align: center;
                    color: white;
                    font-size: 1.2em;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2em;
        }


        @media (max-width: ${variables.tabletL}) {
            .boxes {
                flex-direction: column;

                .box {
                    width: 100%;
                    margin-bottom: 2em;
                    height: 28em;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {}
    `;

