import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 100vh;
        position: relative;
        background-color: white;

        .header {
            width: 100%;
            background-color: #101010;
            padding: 1em 0;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;

            .row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    max-width: 10em;
                }
            }
        }


        @media (max-width: ${variables.tabletL}) {}
        @media (max-width: ${variables.mobileL}) {}
    `;

