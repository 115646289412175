import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 4em 0;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .col-testimonial {
                width: 70%;
                display: flex;
                justify-content: center;
                align-items: stretch;

                .testimonial-image {
                    background-position: center;
                    background-size: cover;
                    width: 40%;
                }
                .testimonial-copy {
                    background-image: url(${require('Theme/images/online/testimonial-bg.jpg')});
                    background-position: center;
                    background-size: cover;
                    width: 60%;
                    padding: 2em;
                    color: white;

                    .testimonial-header {
                        .testimonial-author {
                            font-weight: 900;
                            font-size: 1.5em;

                            .icon {
                                font-size: 2em;
                                position: relative;
                                top: -.3em;
                            }
                        }

                        .testimonial-date {
                            color: #999999;
                        }
                    }

                    .testimonial-paragraph {
                        color: #CCCCCC;
                        margin-top: 1em;
                        padding-top: 1em;
                        border-top: 1px solid #999999
                    }
                }
            }

            .col-fb-review {
                width: 28%;
                background-color: #071736;
                padding: 2em;

                display: flex;
                justify-content: center;
                align-items: center;


                .fb-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    color: white;

                    .fb-logo {
                        height: 4em;
                        width: auto;
                    }

                    .stars {
                        margin-top: 1em;
                    }

                    .review {
                        margin-top: 1em;
                        font-size: 1.1em;
                        font-weight: 900;
                    }

                    .review-count {
                        margin-top: .2em;
                        color: #CCCCCC;
                    }
                }
            }
        }


        @media (max-width: ${variables.tabletL}) {
            .row {
                flex-direction: column;

                .col-testimonial {
                    width: 100%;
                }

                .col-fb-review {
                    width: 100%;
                    margin-top: 2em;
                }
            }
        }
        @media (max-width: ${variables.mobileL}) {
            .row {
                flex-direction: column;

                .col-testimonial {
                    flex-direction: column;

                    .testimonial-image {
                        width: 100%;
                        height: 20em;
                    }
                    .testimonial-copy {
                        width: 100%;
                    }
                }

                .col-fb-review {}
            }
        }
    `;

